import React from 'react';
import { Box } from '@chakra-ui/react';
import Slider from 'react-slick';
import Slide from '@/containers/landingPage/Slide';

import 'slick-carousel/slick/slick.css';

interface IBannerSizeProps {
  xs?: string;
  md?: string;
  lg: string;
}

interface ISlides {
  slides: {
    id: number;
    title?: string | JSX.Element;
    desc?: string | JSX.Element;
    banner: IBannerSizeProps;
    extra?: boolean;
    showBtn: boolean;
    buttons: { text: string; url: string };
    topComp?: JSX.Element;
    contactInfo?: JSX.Element;
  }[];
}

const HeroSectionSlider: React.FC<ISlides> = ({ slides }) => {
  const settings = {
    dots: true,
    infinite: true,
    lazyload: true,
    autoplay: true,
    autoplaySpeed: 3500,
    arrows: false,
    adaptiveHeight: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // eslint-disable-next-line react/no-unstable-nested-components
    appendDots: (dots: any) => (
      <Box
        backgroundColor="transparent"
        borderRadius="10px"
        padding="10px"
        zIndex="10"
        position="absolute"
        bottom={{ xs: '23%', sm: '15%', md: '15%', lg: '12%' }}
        left={{
          xs: '-4%',
          sm: '0%',
          md: '2%',
          lg: '1%',
          //   xl: '-270px',
          xl: '2.5%',
          '2xl': '14.5%',
        }}
        w={{
          xs: '50%',
          sm: '30%',
          md: '20%',
          lg: '20%',
          xl: '20%',
          '2xl': '20%',
        }}
      >
        <ul style={{ margin: '0px' }}>{dots}</ul>
      </Box>
    ),
    dotsClass: 'slick-dots slick-thumb',
  };

  return (
    <Box h="771px" maxH="75vh" as="section">
      <Slider {...settings}>
        {slides.map((slide) => (
          <Box as="section" key={slide.id} h="100%">
            <Slide
              id={slide.id}
              title={slide.title}
              desc={slide.desc}
              banner={slide.banner}
              showBtn={slide.showBtn}
              buttons={slide.buttons}
              extra={slide.extra}
              topComp={slide.topComp}
              contactInfo={slide.contactInfo}
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default HeroSectionSlider;
