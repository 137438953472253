import React from 'react';
import { Box, Flex, Heading, Text, Button, Link } from '@chakra-ui/react';
// import Link from 'next/link';
import Image from 'next/image';
import SectionContainer from '@/components/common/SectionContainer';

// banner size
interface IBannerSizeProps {
  xs?: string;
  md?: string;
  lg: string;
}

export interface ISlideProps {
  id: number;
  title?: string | JSX.Element;
  desc?: string | JSX.Element;
  banner: IBannerSizeProps;
  showBtn: boolean;
  buttons: { text: string; url?: string; external?: boolean };
  extra?: boolean;
  topComp?: JSX.Element;
  contactInfo?: JSX.Element;
}

const Slide: React.FunctionComponent<ISlideProps> = ({
  id,
  title,
  desc,
  banner,
  showBtn,
  buttons,
  extra,
  topComp = null,
  contactInfo = null,
}) => (
  <Flex
    flexDirection="column"
    align="center"
    w="100%"
    h="771px"
    maxH="75vh"
    pos="relative"
    // overflowY='scroll'
    className="slide-container"
  >
    {id === 105 && (
      <Box
        display={{
          xs: 'block',
          md: 'none',
        }}
        // position="absolute"
        // top='20%'
        zIndex="999999"
        // paddingX="20px"
      >
        <Image
          src={banner.lg}
          alt="hero-slider-img"
          className={id === 105 ? '' : 'home-hero-img'}
          style={{
            objectFit: 'contain',
            objectPosition: 'center',
          }}
          fill
          priority={id === 105}
        />
      </Box>
    )}
    <Box
      pos="absolute"
      zIndex="-10"
      top="0"
      left="0"
      right="0"
      h="100%"
      w="100%"
      overflow="hidden"
      opacity={id === 15 ? 1 : 1}
      filter={id === 15 ? 'brightness(60%)' : id === 105 ? 'auto' : ''}
      blur={{
        xs: id === 105 ? '4px' : '0px',
        md: '0px',
      }}
    >
      <Box
        display={{
          xs: id === 5 ? 'none' : 'block',
          md: 'block',
        }}
      >
        <Image
          src={banner.lg}
          alt="hero-slider-img"
          className={id === 5 || id === 105 ? '' : 'home-hero-img'}
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          fill
          priority={id === 1 || id === 105}
        />
      </Box>

      {/* ivca 101 tab view banner  */}
      <Box
        display={{
          xs: id === 5 ? 'block' : 'none',
          md: id === 5 ? 'block' : 'none',
          lg: id === 5 ? 'block' : 'none',
        }}
        className="overlay-image"
        filter={id === 14 ? 'brightness(0)' : ''}
      >
        <Image
          src={banner.md === undefined ? '' : banner.md}
          alt="hero-slider-img"
          className={id === 5 ? '' : 'home-hero-img'}
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          fill
          priority={id === 1}
        />
      </Box>

      {/* ivca 101 mobile view banner  */}
      <Box display={{ xs: id === 5 ? 'block' : 'none', md: 'none' }}>
        <Image
          src={banner.xs === undefined ? '' : banner.xs}
          alt="hero-slider-img"
          style={
            {
              // objectFit: 'cover',
              // objectPosition: 'center',
            }
          }
          fill
          priority={id === 1}
        />
      </Box>
      <Box
        pos="absolute"
        zIndex="10"
        top="0"
        left="0"
        right="0"
        bg="linear-gradient(91.71deg, #0A042D 41.83%, rgba(86, 182, 165, 0.46) 75.2%)"
        height="100%"
        width="100%"
        opacity={id === 1 || id === 105 ? '0.40' : id === 5 ? '0' : '0.7'}
        filter={id === 1 ? 'grayscale(100%)' : 'none'}
      />
    </Box>
    {/* {id === 14 && (
      <SectionContainer
        position="absolute"
        top="0"
        bottom="0"
        pl={{ xs: '0px', lg: '45px' }}
        pr={{ xs: '0px', lg: '45px' }}
      >
        <Flex align="center" justify="center" w="full" h="100%">
          <Img
            h={{ xs: 'auto', lg: 'full' }}
            w={{ xs: 'full', lg: 'auto' }}
            src={banner.lg}
            objectFit="cover"
          />
        </Flex>
      </SectionContainer>
    )} */}
    <SectionContainer
      pl={{ xs: '30px', sm: '40px', md: '55px', lg: '80px', xl: '112px' }}
      height="100%"
      pb={{ xs: '120px', md: '60px' }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      position="relative"
    >
      <Flex
        columnGap={{ md: '30px', lg: '50px', xl: '113px' }}
        justify="space-between"
        flexDir={{ xs: 'column', lg: 'row' }}
      >
        <Flex flexDir="column">
          {topComp}
          {typeof title === 'string' ? (
            <Heading
              as="h1"
              variant="hero"
              w={{ xs: '100%', md: '100%', lg: id === 5 ? '100%' : '70%' }}
              fontSize={
                id === 3
                  ? { xs: '36px', sm: '45px', md: '48px', lg: '52px' }
                  : id === 0 || id === 100 || id === 105
                  ? { xs: '2rem', sm: '2rem', md: '3rem', lg: '3rem' }
                  : { xs: '3rem', sm: '45px', md: '60px', lg: '74px' }
              }
              mb={{ xs: id === 100 || id === 105 ? '5px' : '30px', sm: '0px' }}
              noOfLines={{ xs: 4, sm: 3 }}
            >
              {title}
            </Heading>
          ) : (
            title
          )}
          {desc &&
            (typeof desc === 'string' ? (
              <>
                <Text
                  w={{
                    xs: id === 100 ? '260px' : id === 105 ? '280px' : '100%',
                    md: id === 100 ? '260px' : id === 105 ? '280px' : '100%',
                    lg: id === 100 ? '260px' : id === 105 ? '280px' : '70%',
                  }}
                  variant="heroDesc"
                  mb={{ xs: '12px', md: '20px' }}
                  mt={id === 5 || id === 100 || id === 105 ? '0px' : '15px'}
                  //   display={{
                  //     xs: id === 5 || id === 100 || id === 105 ? 'block' : 'none',
                  //     sm: 'block',
                  //   }}
                  whiteSpace={id === 105 ? 'pre-line' : 'normal'}
                >
                  {desc}
                </Text>{' '}
              </>
            ) : (
              desc
            ))}
          {extra && (
            <Flex
              mt="16px"
              display={{ xs: 'none', sm: 'flex' }}
              columnGap={{ md: '15px', lg: '60px', xl: '90px' }}
              rowGap="16px"
              w="100%"
              mb="16px"
              flexDirection={{ xs: 'column', lg: 'row' }}
            >
              <Box mb={{ xs: '4px', md: '0' }}>
                <Heading
                  as="h3"
                  fontWeight="800"
                  fontSize="44px"
                  lineHeight="48px"
                  color="white"
                  mb="4px"
                  display={{ xs: 'none', lg: 'flex' }}
                >
                  70%
                </Heading>
                <Text
                  variant="heroDesc"
                  fontSize={{ xs: '0.875rem', md: '1rem' }}
                  noOfLines={{ md: 2, lg: 3 }}
                >
                  <Text
                    color="inherit"
                    as="span"
                    fontSize={{ xs: '1.125rem', md: '1.65rem' }}
                    fontWeight="semibold"
                    display={{ xs: 'inline', lg: 'none' }}
                  >
                    70%{' '}
                  </Text>
                  of surveyed LPs say their organizations’ investment policies
                  include an ESG approach.
                </Text>
              </Box>
              <Box mb={{ xs: '4px', md: '0' }}>
                <Heading
                  as="h3"
                  fontWeight="800"
                  fontSize="44px"
                  lineHeight="48px"
                  color="white"
                  mb="4px"
                  display={{ xs: 'none', lg: 'flex' }}
                >
                  85%
                </Heading>
                <Text
                  variant="heroDesc"
                  fontSize={{ xs: '0.875rem', md: '1rem' }}
                  noOfLines={{ md: 2, lg: 3 }}
                >
                  <Text
                    color="inherit"
                    as="span"
                    fontSize={{ xs: '1.125rem', md: '1.65rem' }}
                    fontWeight="semibold"
                    display={{ xs: 'inline', lg: 'none' }}
                  >
                    85%{' '}
                  </Text>
                  of those LPs have ab ESG investment policy fully (52%) or
                  partially(33%) implemented in private equity (PE).
                </Text>
              </Box>
              <Box>
                <Heading
                  as="h3"
                  fontWeight="800"
                  fontSize="44px"
                  lineHeight="48px"
                  color="white"
                  mb="4px"
                  display={{ xs: 'none', lg: 'flex' }}
                >
                  76%
                </Heading>
                <Text
                  variant="heroDesc"
                  fontSize={{ xs: '0.875rem', md: '1rem' }}
                  noOfLines={{ md: 2, lg: 3 }}
                >
                  <Text
                    color="inherit"
                    as="span"
                    fontSize={{ xs: '1.125rem', md: '1.65rem' }}
                    fontWeight="semibold"
                    display={{ xs: 'inline', lg: 'none' }}
                  >
                    76%{' '}
                  </Text>
                  of PE assets under management affected by surveyed LPs with an
                  ESG investment policy implemented in PE.
                </Text>
              </Box>
            </Flex>
          )}
          {contactInfo}
          {showBtn &&
            (id === 15 || id === 14 ? (
              <Link
                href={buttons.url || '#'}
                aria-label={typeof title === 'string' ? title : buttons.text}
                target={buttons.external ? '_blank' : '_self'}
                sx={{
                  marginTop: '30px',

                  '@media screen and (max-width: 767px)': {
                    position: 'absolute',
                    bottom: '13%',
                  },
                }}
              >
                <Button
                  variant="heroBtn"
                  aria-label={typeof title === 'string' ? title : buttons.text}
                  w={{ xs: '130px', md: '157px' }}
                  h={{ xs: '48px', md: '59px' }}
                >
                  {buttons.text}
                </Button>
              </Link>
            ) : id === 100 ? (
              // Render three buttons for id 100
              <Flex gap={{ xs: '8px', md: '16px' }} flexWrap="wrap">
                <Link
                  href="https://www.ivcaconclave.com/"
                  aria-label={
                    typeof title === 'string' ? title : 'Relive the Momentum'
                  }
                  target={buttons.external ? '_blank' : '_self'}
                >
                  <Button
                    variant="heroBtn"
                    aria-label={
                      typeof title === 'string' ? title : 'Relive the Momentum'
                    }
                    w={{ xs: '200px', md: '200px' }}
                    h={{ xs: '28px', md: '50px' }}
                  >
                    Learn more
                  </Button>
                </Link>
              </Flex>
            ) : id === 105 ? (
              <Box
                // display="flex"
                // justifyContent="center"
                // alignItems="center"
                mt="30px"
                // bgColor="red"
                width="100%"
                top={{
                  xs: '70%',
                  sm: '70%',
                  md: '68%',
                  lg: '71%',
                }}
                position="absolute"
                // left="-7%"
                right={{
                  xs: '-22%',
                  ux: '-28%',
                  sm: '-35%',
                  md: '-40%',
                  lg: '-43%',
                }}
              >
                <Link
                  href={buttons.url || '#'}
                  target={buttons.external ? '_blank' : '_self'}
                >
                  <Button
                    variant=""
                    w={{ xs: '175px', sm: '185px', md: '200px' }}
                    h={{ xs: '45px', sm: '45px', md: '60px' }}
                    bgColor="#FEBD2E"
                    shadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                    boxShadow="0px 4px 4px rgba(0, 0, 0, 0.5)"
                    className="btn-animition"
                  >
                    {buttons.text}
                  </Button>
                </Link>
              </Box>
            ) : (
              <Link
                href={buttons.url || '#'}
                aria-label={typeof title === 'string' ? title : buttons.text}
                target={buttons.external ? '_blank' : '_self'}
              >
                <Button
                  variant="heroBtn"
                  aria-label={typeof title === 'string' ? title : buttons.text}
                  w={{ xs: '130px', md: '157px' }}
                  h={{ xs: '48px', md: '59px' }}
                >
                  {buttons.text}
                </Button>
              </Link>
            ))}
        </Flex>
      </Flex>
    </SectionContainer>
  </Flex>
);

export default Slide;
